import joinClassNames from "classnames";

import { getLabelValueType } from "selectors/common";

import { BlankButton } from "basics/button";

import classes from "./styles/classes.module.scss";

interface Props<
  T extends ListOption,
  V = T extends { value: infer V } ? V : T,
> {
  className?: string;
  items: T[];
  onChange?(active: T): void;
  value?: V;
  isDisabled?: boolean;
  classNames?: Partial<{ item: string }>;
}

function Tabs<T extends ListOption>({
  items,
  className,
  value,
  onChange,
  isDisabled = false,
  classNames = {},
}: Props<T>) {
  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      {items.map(item => {
        const option = getLabelValueType(item);

        return (
          <BlankButton
            key={option.value}
            onClick={onChange && (() => onChange(item))}
            disabled={isDisabled}
            aria-selected={option.value === value}
            className={joinClassNames(classes.item, classNames.item)}
          >
            {option.label}
          </BlankButton>
        );
      })}
    </div>
  );
}

export default Tabs;
